export enum CommonConstant {
  _ACCESS_TOKEN = 'accessToken',
  _REFRESH_TOKEN = 'refreshToken',
  _MAX_FILE_SIZE = 20 * 1024 * 1024,
  _ACCEPTED_AVATAR_TYPE = 'image/jpeg, image/jpg, image/png',
  _ACCEPTED_CV_TYPE = 'application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document',
}

export enum Roles {
  _SENECA_ADMIN = 'admin',
  _GUEST = 'guest',
  _CONSULTANT = 'consultant',
  _CORPORATE = 'corporate',
}
export enum PathRoot {
  _ADMIN = '/admin',
  _CONSULTANT = '/consultant',
  _ORGANIZATION = '/organization',
}

export enum EFindType {
  _WORKS = 'works',
  _CONSULTANTS = 'consultants',
}

export enum Routes {
  _HOME = '/',
  _OVERVIEW = '/overview',

  // auth groups
  _SIGN_IN = '/auth/sign-in',
  _FORGOT_PASSWORD = '/auth/forgot-password',
  _RESET_PASSWORD = '/auth/reset-password',
  _SIGN_UP = '/auth/sign-up',
  _VERIFY_EMAIL = '/auth/verify-email',

  // privacy policy
  _TOS = '/#',
  _PRIVACY_POLICY = '/##',
  _USER_AGREEMENT = '/###',

  // admin group
  _ADMIN_OVERVIEW = '/admin/overview',
  _ADMIN_USER_ACCOUNT = '/admin/user',
  _ADMIN_CORPORATE = '/admin/organization',
  _ADMIN_CORPORATE_PROFILE = '/admin/organization/:id',
  _ADMIN_CORPORATE_CREATE = '/admin/organization/create',
  _ADMIN_CONSULTANT = '/admin/consultant',
  _ADMIN_CONSULTANT_PROFILE = '/admin/consultant/:id',
  _ADMIN_APPLICATION = '/admin/application',
  _ADMIN_APPLICATION_DETAIL = '/admin/application/:id',
  _ADMIN_ALL_JOB = '/admin/project',
  _ADMIN_JOB_CREATE = '/admin/project/create',
  _ADMIN_JOB_DETAIL = '/admin/project/:id',
  _ADMIN_TENDER = '/admin/tender',
  _ADMIN_TENDER_DETAIL = '/admin/tender/:id',
  _ADMIN_PROFILE = '/admin/me',
  _ADMIN_ACCOUNT_SETTINGS = '/admin/account-settings',

  // consultant group
  _CON_APPLIED_JOBS = '/consultant/applied-jobs',
  _CON_APPLIED_JOBS_DETAIL = '/consultant/applied-jobs/:id',
  _CON_OVERVIEW = '/consultant/overview',
  _CON_FAVORITE_JOBS = '/consultant/favorite-jobs',
  _CON_FAVORITE_JOBS_DETAIL = '/consultant/favorite-jobs/:id',
  _CON_PROFILE = '/consultant/me',
  _CON_ACCOUNT_SETTINGS = '/consultant/account-settings',

  // organization group
  _ORG_SAVED_CONSULTANT = '/organization/saved-consultant',
  _ORG_ALL_PROJECTS = '/organization/project',
  _ORG_ALL_APPLICATIONS = '/organization/application',
  _ORG_APPLICATION_DETAIL = '/organization/application/:id',
  _ORG_PROJECT_NEW = '/organization/new-project',
  _ORG_PROJECT_NEW_FLOW = '/organization/project-step',
  _ORG_PROJECT_DETAIL_FLOW = '/organization/project-step/:id',
  _ORG_PROJECT_DETAIL = '/organization/project/:id',
  _ORG_PROFILE = '/organization/me',
  _ORG_ACCOUNT_SETTINGS = '/organization/account-settings',
  _ORG_OVERVIEW = '/organization/overview',

  // public group
  _FIND_CONSULTANT = '/find/consultants',
  _FIND_WORK = '/find/works',
}

export enum DefaultMessages {
  _ERROR = 'Hmm, something went wrong. Please try again later.',
  _SUCCESS = 'Success!',
  _WARNING = 'Warning!',
  _INFO = 'Info!',
  _CONFIRM = 'Confirm!',
  _ERROR_TITLE = 'Error!',
  _REGISTER_SUCCESS = 'Your account has been created successfully!',
  _LOGOUT_SUCCESS = 'You have been logged out successfully!',
  _LOGIN_SUCCESS = 'You have been logged in successfully!',
  _CREATE_USER_SUCCESS = 'User has been created successfully!',
}

export enum DateTimeFormat {
  _FULL_DATE = 'DD MMMM YYYY',
  _FULL_DATA_TIME_SECOND = 'YYYY-MM-DD HH:mm:ss',
  _DATE = 'YYYY-MM-DD',
  _SHORT_DATE = 'DD MMM, YYYY',
  _MONTH_YEAR = 'MMMM YYYY',
}

export const authRoutes = [
  Routes._SIGN_IN,
  Routes._SIGN_UP,
  Routes._FORGOT_PASSWORD,
  Routes._RESET_PASSWORD,
  Routes._VERIFY_EMAIL,
];

export const PASSWORD_REGEX =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[~!@#$%^&*()_+=\\[\]{}\\|;':",.<>/?-])[A-Za-z\d~!@#$%^&*()_+=\\[\]{}\\|;':",.<>/?-]{8,64}$/;

export enum PROJECT_STATUS {
  _DRAFT = 'draft',
  _ACTIVE = 'active',
  _INACTIVE = 'inactive',
  _EXPIRED = 'expired',
}

export const LIST_STATUS_APPLICATION = [
  {
    label: 'Accepted',
    value: 'accepted',
    color: 'success',
  },
  { label: 'Rejected', value: 'rejected', color: 'error' },
  {
    label: 'Wait Approve',
    value: 'awaiting_for_approval',
    color: 'warning',
  },
];
export const LIST_STATUS_JOB = [
  {
    label: 'Active',
    value: 'active',
    color: 'success',
  },
  {
    label: 'Draft',
    value: 'draft',
    color: 'blue',
  },
  {
    label: 'Inactive',
    value: 'inactive',
    color: 'error',
  },
  {
    label: 'Expired',
    value: 'expired',
    color: 'error',
  },
];

export enum APPLICATION_STATUS {
  _ACCEPTED = 'accepted',
  _REJECTED = 'rejected',
  _WAITING_APPROVAL = 'awaiting_for_approval',
}

export const experienceLevels = [
  { value: 'Entry', label: 'Entry' },
  { value: 'Intermediate', label: 'Intermediate' },
  { value: 'Expert', label: 'Expert' },
];

export const WEBSITE_PREFIX = 'https://';

export enum TypeFindJob {
  _PROJECT = 'project',
  _TENDER = 'tender',
  _AI = 'ai',
}

export enum BREAK_POINTS {
  XS = 425,
  SM = 640,
  MD = 768,
  LG = 1024,
  XL = 1280,
  XXL = 1536,
}

import { createSlice } from '@reduxjs/toolkit';

type NotificationType = 'success' | 'info' | 'warning' | 'error';
export interface INotification {
  type: NotificationType;
  msgTitle: string;
  msgContent?: string;
}

export type TypeViewDrawer =
  | 'project'
  | 'crawl'
  | 'consultant'
  | 'corporate'
  | 'application'
  | null;

interface IViewDrawer {
  visible: boolean;
  title: string;
  id: string;
  type: TypeViewDrawer;
  isAdmin?: boolean;
}

interface ICommonState {
  isLoading: boolean;
  notification: INotification;
  industries?: any;
  industriesParsed?: any;
  locations?: any;
  viewDrawer: IViewDrawer;
}

const initialState: ICommonState = {
  isLoading: false,
  notification: {
    type: '' as NotificationType,
    msgTitle: '',
    msgContent: '',
  },
  industries: [],
  industriesParsed: {},
  viewDrawer: {
    visible: false,
    title: '',
    id: '',
    type: null,
  },
};

const commonSlice = createSlice({
  name: 'common',
  initialState,
  reducers: {
    setLoading(state, action) {
      state.isLoading = action.payload;
    },

    setNotification(state, action: { payload: INotification }) {
      state.notification = action.payload;
    },

    clearNotification(state) {
      state.notification = {
        type: '' as NotificationType,
        msgTitle: '',
        msgContent: '',
      };
    },
    setIndustries(state, action) {
      state.industries = action.payload;

      const { multi_industry, region } = action.payload;

      const industriesParsed: any = {};

      const listIndicators = ['TRBC', 'GICS', 'SICS'];

      listIndicators.forEach((indicator) => {
        multi_industry[indicator].forEach((item: any) => {
          item.children.forEach((child: any) => {
            child.children.forEach((subChild: any) => {
              if (!industriesParsed[indicator]) {
                industriesParsed[indicator] = [];
              }

              industriesParsed[indicator].push(subChild);
            });
          });
        });
      });

      state.industriesParsed = industriesParsed;
      state.locations = region;
    },

    setViewDrawer(state, action: { payload: IViewDrawer }) {
      state.viewDrawer = action.payload;
    },

    clearViewDrawer(state) {
      state.viewDrawer = {
        visible: false,
        title: '',
        id: '',
        type: null,
      };
    },
  },
});

export const {
  setLoading,
  setNotification,
  clearNotification,
  setIndustries,
  setViewDrawer,
  clearViewDrawer,
} = commonSlice.actions;

export default commonSlice.reducer;

'use client';

import axios, { isAxiosError } from 'axios';
import dayjs from 'dayjs';

import store from '@/store';
import { setNotification } from '@/store/feature/common.slice';

import api from '@/services/api/api.service';
import endpointConfig from '@/services/api/endpoint.config';

import { DefaultMessages } from './common.constant';

export const capitalize = (str: string) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const jwtDecode = (token: string) => {
  if (!token) {
    return null;
  }

  // decodee access token
  const base64Url = token.split('.')[1];

  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');

  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split('')
      .map((c) => '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2))
      .join('')
  );

  return JSON.parse(jsonPayload);
};

export const isTokenExpired = (token: string) => {
  const decoded = jwtDecode(token);

  if (!decoded) {
    return true;
  }

  return decoded.exp < Date.now() / 1000;
};
export const truncateString = (str = '', maxLength = 16) => {
  return str.length > maxLength ? str.slice(0, maxLength) + '...' : str;
};
export const toDurationString = (fromTime: any, toTime: any) => {
  return (
    dayjs(fromTime).format('MMM YYYY') +
    ' - ' +
    dayjs(toTime).format('MMM YYYY')
  );
};

export const uploadFile = async (file: any) => {
  const ext = file.name.split('.').pop();

  const rewriteFileName = `${Date.now()}.${ext}`;

  const { data } = await api.post(endpointConfig.filePresigned, {
    fileName: rewriteFileName,
  });
  await axios.put(data.link, file, {
    headers: {
      'Content-Type': file.type,
      'x-ms-blob-type': 'BlockBlob',
    },
  });
  return data.link.split('?')[0];
};

export const getErrorMessage = (err: any) => {
  if (isAxiosError(err)) {
    return err.response?.data?.message;
  }
  return err.message;
};

export const queryBuilder = (query: { [x: string]: string | null }) => {
  const search = new URLSearchParams();
  Object.keys(query).forEach((key) => {
    if (query[key]) {
      search.append(key, query[key] as string);
    }
  });

  return search.toString();
};

export const generateAvatar = (name: string) => {
  const firstLetter = name?.charAt(0)?.toUpperCase();

  // draw canvas
  const canvas = document.createElement('canvas');
  canvas.width = 100;
  canvas.height = 100;
  const context = canvas.getContext('2d');
  if (context) {
    context.fillStyle = '#f0f0f0';
    context.fillRect(0, 0, 100, 100);
    // context.beginPath();
    // context.moveTo(50, 0);
    // context.lineTo(50, canvas.height);
    // context.stroke();
    // context.moveTo(0, 50);
    // context.lineTo(canvas.width, 50);
    // context.stroke();
    // context.closePath();
    context.fillStyle = '#2f5c85';
    context.font = '50px Arial';
    context.textAlign = 'center';
    const textMetrics = context.measureText(firstLetter);
    const actualHeight =
      textMetrics.actualBoundingBoxAscent +
      textMetrics.actualBoundingBoxDescent;
    const x = 100 / 2;
    const y = (100 + actualHeight) / 2;
    context.fillText(firstLetter, x, y);
  }

  return canvas.toDataURL();
};
export const setEndOfDay = (input: any) => {
  if (!input) {
    return input;
  }
  if (Array.isArray(input)) {
    return [
      input[0],
      input[1].set('hour', 23).set('minute', 59).set('second', 59),
    ];
  }
  return input.set('hour', 23).set('minute', 59).set('second', 59);
};
export const getFileName = (url: string) => {
  return url.split(/[#?]/)?.[0]?.split('/')?.pop()?.trim() ?? '';
};
export const getFileExtension = (url = '') => {
  return getFileName(url).split('.')[1];
};

export const downloadFromUrl = async (url = '', fileName?: string) => {
  if (!url) {
    return;
  }
  try {
    const downLoadName = fileName || getFileName(url);
    const res = await axios.get(url, {
      responseType: 'blob',
    });
    const urlDownload = window.URL.createObjectURL(new Blob([res.data]));
    const link = document.createElement('a');
    link.href;
    link.setAttribute('href', urlDownload);
    link.setAttribute('download', downLoadName);
    document.body.appendChild(link);
    link.click();
    link.remove();
  } catch (error) {
    store.dispatch(
      setNotification({
        type: 'error',
        msgContent: 'Download failed. Please try again!',
        msgTitle: DefaultMessages._ERROR_TITLE,
      })
    );
  }
};

export const formatBudget = (budget: number | string, code?: string) => {
  if (
    budget === 0 ||
    budget === null ||
    budget === undefined ||
    isNaN(budget as number) ||
    budget === Infinity
  ) {
    return 'Negotiable';
  }

  const formatter = new Intl.NumberFormat(undefined, {
    style: 'currency',
    currency: !code ? 'USD' : code,
    currencySign: 'standard',
    currencyDisplay: 'symbol',
  });

  return formatter.format(Number(budget));
};

export const currentMonthDate = (): [string, string] => {
  return [
    dayjs().startOf('M').toISOString(),
    setEndOfDay(dayjs().set('millisecond', 0)).toISOString(),
  ];
};

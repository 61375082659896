import { combineReducers } from '@reduxjs/toolkit';

import authSlice from './feature/auth.slice';
import commonSlice from './feature/common.slice';
import searchSlice from './feature/search.slice';
import userSlice from './feature/user.slice';

const rootReducer = combineReducers({
  common: commonSlice,
  auth: authSlice,
  user: userSlice,
  search: searchSlice,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;

import { createSlice } from '@reduxjs/toolkit';

interface ISearchState {
  filter: { [x: string]: string };
  results: any[];
}

const initialState: ISearchState = {
  filter: {},
  results: [],
};

const searchSlice = createSlice({
  name: 'search',
  initialState,
  reducers: {
    setResults(state, action) {
      state.results = action.payload;
    },
    setFilter(state, action) {
      state.filter = action.payload;
    },
  },
});

export const { setResults, setFilter } = searchSlice.actions;

export default searchSlice.reducer;

import { createSlice } from '@reduxjs/toolkit';

import { IUserState } from '@/types/common.type';

const initialState: {
  userInfo: IUserState;
} = { userInfo: {} };

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUserInfo(state, action) {
      state.userInfo = action.payload;
    },
  },
});

export const { setUserInfo } = userSlice.actions;

export default userSlice.reducer;

import axios from 'axios';

const baseURL = process.env.API_URL;

const apiVersion = 'api/v1';

const api = axios.create({
  baseURL: baseURL + apiVersion,
  headers: {
    'Content-Type': 'application/json',
  },
});

export default api;

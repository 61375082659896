// eslint-disable-next-line import/no-anonymous-default-export
export default {
  signIn: '/auth/login',
  signUp: '/auth/sign-up',
  signUpSocial: '/auth/sign-up-social',
  signInSocial: '/auth/login-social',
  verifyEmail: '/auth/verify-email',
  forgotPassword: '/auth/forgot-password',
  resetPassword: '/auth/reset-password',
  resendActivation: '/auth/resend-email-verification',
  me: '/auth/me',
  refreshToken: '/auth/refresh-token',
  updatePassword: '/auth/me/password',
  filePresigned: '/file/presigned',

  //industries
  industries: '/industries',

  // pre-signed url
  presignedUrl: '/file/presigned',

  // consultant
  consultants: '/consultants',
  consultantsSaved: '/consultants/saved',

  // organization
  corporateMe: '/corporates/me',
  corporate: '/corporates',
  corporateStatus: '/corporates/{id}/status',

  // expertise
  expertise: '/expertises',
  usersStatus: '/users/{id}/status',
  // users
  users: '/users',

  //job
  jobs: '/jobs',
  saveJob: '/saved-jobs',
  //applications
  applications: '/applications',

  // dashboard
  dashboardOverview: '/dashboard/overview',
  dashboardCount: '/dashboard/count',
  homepage: '/homepage/overview',

  //crawl job
  crawlJob: '/crawled-jobs',
};
